import React from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom

const Header = () => {
  return (
    <header id="header" className="header fixed-top">
      <div className="branding d-flex align-items-center">
        <div className="container position-relative d-flex align-items-center justify-content-between">
          <Link to="/" className="logo d-flex align-items-center">
            {/* Uncomment the line below if you also wish to use an image logo */}
            <img src="assets/img/logo.png" alt="logo" />
          </Link>
          <nav id="navmenu" className="navmenu">
            <ul>
              <li>
                <Link to="/about">About</Link>
              </li>
              <li className="dropdown">
                <Link to="#">
                  <span>The Hub</span>{" "}
                  <i className="bi bi-chevron-down toggle-dropdown" />
                </Link>
                <ul className="tranav">
                  <li>
                    <Link to="/grow-your-business">Grow your business</Link>
                  </li>
                  <li>
                    <Link to="/invest-in-business">Invest in business</Link>
                  </li>
                  <li>
                    <Link to="/execute-transactions">Execute transactions</Link>
                  </li>
                  <li>
                    <Link to="/exit-your-business">Exit your business</Link>
                  </li>
                  <li>
                    <Link to="/raise-funds">Raise funds</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/pricing">Pricing</Link>
              </li>
              <li>
                <Link to="/login" className="btn-get-started active">
                  Log in
                </Link>
              </li>
              <li>
                <Link to="/signup" className="btn-get-started">
                  Sign Up
                </Link>
              </li>
            </ul>
          </nav>
          <i className="mobile-nav-toggle d-xl-none bi bi-list" />
        </div>
      </div>
    </header>
  );
};

export default Header;
