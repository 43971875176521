import React from "react";
import MainSection from "../../Components/MainSection";

const index = () => {
  return (
    <>
      <MainSection />
    </>
  );
};

export default index;
