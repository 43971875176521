import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer id="footer" className="footer accent-background">
      <div className="container footer-top">
        <div className="row gy-4">
          <div className="col-lg-2 col-6 footer-links">
            <h4 className="footer-text">The Hub</h4>
            <ul>
              <li>
                <Link to="/buy-side">Buy Side</Link>
              </li>
              <li>
                <Link to="/sell-side">Sell Side</Link>
              </li>
              <li>
                <Link to="/pricing">Pricing</Link>
              </li>
              <li>
                <Link to="/referral">Referral</Link>
              </li>
              <li>
                <Link to="/faq">FAQ</Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-2 col-6 footer-links">
            <h4 className="footer-text">Who we cater</h4>
            <ul>
              <li>
                <Link to="/tbc">TBC</Link>
              </li>
              <li>
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li>
                <Link to="/dashboard-landing">Dashboard Landing</Link>
              </li>
              <li>
                <Link to="/account">Account</Link>
              </li>
              <li>
                <Link to="/mandatory">Mandatory</Link>
              </li>
            </ul>
          </div>
          {/* Additional footer sections */}
        </div>
      </div>
      <div className="container copyright text-left">
        <p>©2024 CorpFin Hub | All rights reserved</p>
      </div>
    </footer>
  );
};

export default Footer;
