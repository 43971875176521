import React from "react";

const index = () => {
  return (
    <>
      <main className="main">
        <section id="contact" className="contact section mt-5 sectionbgabout">
          {/* Section Title */}
          <div
            className="container aboutpad"
            data-aos="fade-up"
            data-aos-delay={100}
          >
            <div className="row gx-lg-0 gy-4">
              <div className="col-lg-5 ">
                <div className="w-75 landingPage-hero aboutdivbox">
                  <h5 className="text-white about16m">
                    About Us... Lorem ipsum dolor sit amet consectetur
                    adipisicing elit. Saepe nisi vitae autem deleniti numquam,
                    debitis quae{" "}
                  </h5>
                </div>
                {/* End Pricing Item */}
              </div>
              <div className="col-lg-6"></div>
              {/* End Contact Form */}
            </div>
          </div>
        </section>
        {/* /Contact Section */}
        <section id="contact" className="contact section ">
          {/* Section Title */}
          <div className="container" data-aos="fade-up" data-aos-delay={100}>
            <div className="row gx-lg-0 gy-4">
              <div className="col-lg-12">
                <div className="container">
                  <ul className="tabbox">
                    <li>
                      {" "}
                      <a href="#team">Team</a>{" "}
                    </li>
                    <li>
                      {" "}
                      <a href="#partners">Partners</a>{" "}
                    </li>
                    <li>
                      {" "}
                      <a href="#whyus">Why us? </a>
                    </li>
                  </ul>
                </div>
                <div className="cards-box" id="team">
                  <div id="Team" className="tabcontent">
                    <div className="card mb-3 w-75 tabbgabout1">
                      <div className="row g-0">
                        <div className="col-md-4 text-center img-card">
                          <img
                            className="card-img-left example-card-img-responsive radiusabout"
                            src="assets/img/cardleft.png"
                          />
                          <h4 className="card-title h5 h4-sm colorEBB42C">
                            Himanshu Singh, CEO
                          </h4>
                        </div>
                        <div className="col-md-8">
                          <div className="card-body">
                            <p className="card-text colorEBB42C">
                              The drop in May's inflation figure was driven by a
                              slight fall in prices for food and soft drinks,
                              and slower price rises for recreation and culture
                              and furniture and household goods. The drop in
                              May's inflation figure was driven by a slight fall
                              in prices for food and soft drinks, and slower
                              price rises for recreation and culture and
                              furniture and household goods. <br />
                              <br />
                              The drop in May's inflation figure was driven by a
                              slight fall in prices for food and soft drinks,
                              and slower price rises for recreation and culture
                              and furniture and household goods.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="Partners" className="tabcontent">
                    <div className="card mb-3 w-75 bgdivaboutpage">
                      <div className="row g-0">
                        <div className="col-md-4 text-center img-card">
                          <img
                            className="card-img-left example-card-img-responsive radiusabout"
                            src="assets/img/cardleftt.png"
                          />
                          <h4 className="card-title h5 h4-sm colorwhite">
                            Deepak Mathur, CTO
                          </h4>
                        </div>
                        <div className="col-md-8">
                          <div className="card-body">
                            <p className="card-text colorwhite">
                              The drop in May's inflation figure was driven by a
                              slight fall in prices for food and soft drinks,
                              and slower price rises for recreation and culture
                              and furniture and household goods. The drop in
                              May's inflation figure was driven by a slight fall
                              in prices for food and soft drinks, and slower
                              price rises for recreation and culture and
                              furniture and household goods.
                              <br />
                              <br />
                              The drop in May's inflation figure was driven by a
                              slight fall in prices.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="partners" className="tabcontent">
                    <div className="card mb-3 w-75 tabbgabout1">
                      <div className="row">
                        <div className="col-12 text-center mb-3 mt-3">
                          <h4 className="card-title  h4-sm colorEBB42C">
                            Partners
                          </h4>
                        </div>
                      </div>
                      <div className="partners-cards mx-auto mb-3">
                        <div className="img1 text-center">
                          <img src="assets/img/cardleft.png" alt="image" />
                          <h6 className="card-title pt-2 h4-sm colorEBB42C">
                            ABC Company
                          </h6>
                        </div>
                        <div className="img1 text-center">
                          <img src="assets/img/cardleftt.png" alt="image" />
                          <h6 className="card-title pt-2 h4-sm colorEBB42C">
                            ABC Company
                          </h6>
                        </div>
                        <div className="img1 text-center">
                          <img src="assets/img/cardleft.png" alt="image" />
                          <h6 className="card-title pt-2 h4-sm colorEBB42C">
                            ABC Company
                          </h6>
                        </div>
                        <div className="img1 text-center">
                          <img src="assets/img/cardleftt.png" alt="image" />
                          <h6 className="card-title pt-2 h4-sm colorEBB42C">
                            ABC Company
                          </h6>
                        </div>
                        <div className="img1 text-center">
                          <img src="assets/img/cardleft.png" alt="image" />
                          <h6 className="card-title pt-2 h4-sm colorEBB42C">
                            ABC Company
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="whyus" className="tabcontent">
                    <div className="card mb-3 w-75 bgdivaboutpage">
                      <div className="row g-0">
                        <div className="col-md-8">
                          <div className="card-body padding50left">
                            <h4 className="card-title h5 h4-sm colorwhite">
                              Why us
                            </h4>
                            <p className="card-text colorwhite">
                              The drop in May's inflation figure was driven by a
                              slight fall in prices for food and soft drinks,
                              and slower price rises for recreation and culture
                              and furniture and household goods. The drop in
                              May's inflation figure was driven by a slight fall
                              in prices for food and soft drinks, and slower
                              price rises for recreation and culture and
                              furniture and household goods.
                            </p>
                          </div>
                        </div>
                        <div className="col-md-4 text-center img-card">
                          <img
                            className="card-img-left example-card-img-responsive boardradiouabout"
                            src="assets/img/maskk.png"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6"></div>
            {/* End Contact Form */}
          </div>
        </section>
        {/* /Contact Section */}
        <section id="contact" className="contact section">
          {/* Section Title */}
          <div
            className="container sectionabout"
            data-aos="fade-up"
            data-aos-delay={100}
          >
            <div className="row gx-lg-0 gy-4">
              <div className="col-lg-5">
                <div className="w-75 landingPage-hero bgaboutpage">
                  <h5 className="text-white 16lineheight">
                    More closing About us text......... TBC
                  </h5>
                </div>
                {/* End Pricing Item */}
              </div>
              <div className="col-lg-6"></div>
              {/* End Contact Form */}
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default index;
