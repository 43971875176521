// LoginPage.js
import React from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { LinkedIn } from 'react-linkedin-login-oauth2';
import './LoginPage.css'; // Optional: For custom styles

const LoginPage = () => {
    console.log('LoginPage component is rendering'); // Debugging log
    // Handle Google login success
    const handleGoogleLoginSuccess = (response) => {
        console.log('Google Login Success:', response);
        // Handle the authentication response (e.g., send to backend)
    };

    // Handle Google login error
    const handleGoogleLoginError = (error) => {
        console.error('Google Login Error:', error);
    };

    // Handle LinkedIn login success
    // const handleLinkedInLoginSuccess = (response) => {
    //     console.log('LinkedIn Login Success:', response);
    //     // Handle the authentication response (e.g., send to backend)
    // };

    // Handle LinkedIn login failure
    // const handleLinkedInLoginFailure = (error) => {
    //     console.error('LinkedIn Login Error:', error);
    // };

    return (
        <div className="login-page">
            <h2>Login</h2>

            {/* Google Login */}
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
                <GoogleLogin
                    onSuccess={handleGoogleLoginSuccess}
                    onError={handleGoogleLoginError}
                />
            </GoogleOAuthProvider>

            {/* LinkedIn Login */}
            {/* <LinkedIn
                clientId={process.env.REACT_APP_LINKEDIN_CLIENT_ID}
                onFailure={handleLinkedInLoginFailure}
                onSuccess={handleLinkedInLoginSuccess}
                redirectUri="http://localhost:3000/linkedin"
                renderElement={({ onClick, disabled }) => (
                    <button onClick={onClick} disabled={disabled}>
                        Login with LinkedIn
                    </button>
                )}
            /> */}
        </div>
    );
};

export default LoginPage;
