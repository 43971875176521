import React from "react";
import { Link } from "react-router-dom";

const MainSection = () => {
  return (
    <main className="main">
      {/* Section 1: Landing Page Hero */}
      <section id="contact" className="contact section mainbgclass">
        <div className="container" data-aos="fade-up" data-aos-delay={100}>
          <div className="row gx-lg-0 gy-4">
            <div className="col-lg-5">
              <div className="w-75 landingPage-hero contatbg2">
                <p className="text-white 16em">
                  Welcome to CorpFinHub platform, you are welcome again... and
                  again. Come join the revolution. This is CorpFinHub platform.
                  come join...
                </p>
                <div className="buy">
                  <a href="#">
                    <button className="buy-side active">Buy Side</button>
                  </a>
                  <a href="#">
                    <button className="buy-side">Sell Side</button>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6"></div>
          </div>
        </div>
      </section>
      {/* Section 2: Play Section */}
      <section>
        <div
          className="container sectionbg"
          data-aos="fade-up"
          data-aos-delay={100}
        >
          <div className="flexPlay">
            <img src="assets/img/play.png" alt="play" />
            <p className="text-white">Play</p>
          </div>
        </div>
      </section>
      {/* Section 3: Buy Side and Sell Side Details */}
      <section id="contact" className="contact section1">
        <div
          className="container bgpad"
          data-aos="fade-up"
          data-aos-delay={100}
        >
          <div className="row">
            <div className="col-12 col-md-6 col-lg-6 box-items">
              <div className="pricing-items">
                <div className="h3 buy-side marbt10">
                  <h3>Buy Side</h3>
                </div>
                <div className="bdrclass">
                  <div className="contents pad10">
                    <ul>
                      <li>
                        {" "}
                        <span>Get access to all deals About us...</span>
                      </li>
                      <li>
                        {" "}
                        <span>tell us more about us Get</span>
                      </li>
                      <li>
                        {" "}
                        <span>access to all deals About us...</span>
                      </li>
                      <li>
                        {" "}
                        <span>tell us more about us</span>
                      </li>
                      <li>
                        {" "}
                        <span>Get access to all deals</span>
                      </li>
                      <li>
                        {" "}
                        <span>About us... tell us more about us</span>
                      </li>
                      <li>
                        {" "}
                        <span>Get access to all deals</span>
                      </li>
                      <li>
                        {" "}
                        <span>About us... tell us more about us</span>
                      </li>
                      <li>
                        {" "}
                        <span>About us... tell us more about us</span>
                      </li>
                      <li>
                        {" "}
                        <span>About us... tell us more about us</span>
                      </li>
                    </ul>
                  </div>
                  <div className="buy-img">
                    <img
                      src="assets/img/Maskk.png"
                      alt="buy image"
                      width="350px"
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* Sell Side */}
            <div className="col-12 col-md-6 col-lg-6 box-items">
              <div className="pricing-items">
                <div className="h3 buy-side">
                  <h3>Sell Side</h3>
                </div>
                <div className="padbigsize">
                  <div className="contents pad10">
                    <ul>
                      <li>
                        {" "}
                        <span>Get access to all deals About us...</span>
                      </li>
                      <li>
                        {" "}
                        <span>tell us more about us Get</span>
                      </li>
                      <li>
                        {" "}
                        <span>access to all deals About us...</span>
                      </li>
                      <li>
                        {" "}
                        <span>tell us more about us</span>
                      </li>
                      <li>
                        {" "}
                        <span>Get access to all deals</span>
                      </li>
                      <li>
                        {" "}
                        <span>About us... tell us more about us</span>
                      </li>
                      <li>
                        {" "}
                        <span>Get access to all deals</span>
                      </li>
                      <li>
                        {" "}
                        <span>About us... tell us more about us</span>
                      </li>
                      <li>
                        {" "}
                        <span>About us... tell us more about us</span>
                      </li>
                      <li>
                        {" "}
                        <span>About us... tell us more about us</span>
                      </li>
                    </ul>
                  </div>
                  <div className="buy-img">
                    <img
                      src="assets/img/Maskk.png"
                      alt="buy image"
                      width="350px"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Section 4: About Us */}
      <section id="contact" className="contact">
        <div
          className="container padcolor"
          data-aos="fade-up"
          data-aos-delay={100}
        >
          <div className="aboutUs">
            <div>
              <h3 className="text-white">About Us</h3>
              <ul>
                <li>
                  <i className="bi bi-check"></i>
                  <span>
                    Get access to all deals About us...Get access to all deals
                    About us...Get access to all deals About us...Get access to
                    all deals About us...
                  </span>
                </li>
                <li>
                  <i className="bi bi-check"></i>
                  <span>
                    Get access to all deals About us...Get access to all deals
                    About us...Get access to all deals About us...Get access to
                    all deals About us...
                  </span>
                </li>
                <li>
                  <i className="bi bi-check"></i>
                  <span>
                    Get access to all deals About us...Get access to all deals
                    About us...Get access to all deals About us...Get access to
                    all deals About us...
                  </span>
                </li>
                <li>
                  <i className="bi bi-check"></i>
                  <span>
                    Get access to all deals About us...Get access to all deals
                    About us...Get access to all deals About us...Get access to
                    all deals About us...
                  </span>
                </li>
                <li>
                  <i className="bi bi-check"></i>
                  <span>
                    Get access to all deals About us...Get access to all deals
                    About us...Get access to all deals About us...Get access to
                    all deals About us...
                  </span>
                </li>
              </ul>
            </div>
            <div>
              <img src="assets/img/Maskk.png" alt="maskk" />
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default MainSection;
